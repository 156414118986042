import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

function NotiFooter({
  isDesktop,
  isMobilePaddingAdd,
}: {
  isDesktop?: boolean;
  isMobilePaddingAdd?: boolean;
}) {
  const router = useRouter();
  return (
    <div
      className={`${
        isDesktop
          ? "w-screen max-w-[800px] py-[104px]"
          : isMobilePaddingAdd
          ? "w-full py-[104px]"
          : "w-full pb-9"
      }  px-6 pt-9 bg-slate-100 flex-col justify-start items-start gap-2 inline-flex`}
    >
      <div className="w-[100px] relative">
        {/* <div className="w-[75.88px]  left-[20.11px] top-[4.01px] absolute"></div> */}
        <Image
          src="/images/riskweatherlogo.svg"
          alt="logo"
          width={100}
          height={18.25}
        />
      </div>
      <div className={`self-stretch ${TYPO.TYPO9_RG} text-gray40`}>
        {router.locale === "ko"
          ? "리스크웨더에서 제공하는 투자 정보는 고객의 투자 판단을 위한 단순 참고용일뿐, 투자 제안 및 권유 ∙ 종목 추천을 위해 작성된 것이 아닙니다. 본 데이터를 활용한 투자에 대한 모든 책임은 데이터 이용자에게 있음을 알려드립니다."
          : "The investment information provided by Riskweather is for reference purposes only and is not intended as investment advice, solicitation, or recommendation of specific securities. Users are solely responsible for any investment decisions based on this data."}
      </div>
    </div>
  );
}
export default NotiFooter;
