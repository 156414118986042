import { TYPO } from "styles/Typo";
import Icon from "../Icons";

// 버튼 타입과 크기를 위한 타입 정의
type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "text_gray"
  | "text_blue"
  | "link_gray"
  | "link_blue"
  | "disabled";
type ButtonSize = "sm" | "md" | "lg" | "xl";

interface ButtonProps {
  type: ButtonType;
  size: ButtonSize;
  disabled?: boolean;
  text: string;
  plus?: boolean;
  width?: string;
  btnClass?: string;
  clickHandler?: any;
  icon?: string;
  iconHeight?: number;
  iconWidth?: number;
}

const Fill = ({ type }: { type: string }) => {
  let fill = "";
  switch (type) {
    case "primary":
      fill = "white";
      break;
    case "secondary":
      fill = "#0F62FE";
      break;
    case "tertiary":
      fill = "#697077";
      break;
    case "text_gray":
      fill = "#878D96";
      break;
    case "text_blue":
      fill = "#4589FF";
      break;
    case "link_gray":
      fill = "#878D96";
      break;
    case "link_blue":
      fill = "#4589FF";
      break;
    case "disabled":
      fill = "#A2A9B0";
      break;
  }
  return fill;
};

const Plus = ({ type }: { type: string }) => {
  let fill = "";
  switch (type) {
    case "primary":
      fill = "white";
      break;
    case "secondary":
      fill = "#0F62FE";
      break;
    case "tertiary":
      fill = "#697077";
      break;
    case "text_gray":
      fill = "#878D96";
      break;
    case "text_blue":
      fill = "#4589FF";
      break;
    case "link_gray":
      fill = "#878D96";
      break;
    case "link_blue":
      fill = "#4589FF";
      break;
    case "disabled":
      fill = "#A2A9B0";
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7 8.66667H2.83333C2.59722 8.66667 2.39931 8.58681 2.23958 8.42708C2.07986 8.26736 2 8.06944 2 7.83333C2 7.59722 2.07986 7.39931 2.23958 7.23958C2.39931 7.07986 2.59722 7 2.83333 7H7V2.83333C7 2.59722 7.07986 2.39931 7.23958 2.23958C7.39931 2.07986 7.59722 2 7.83333 2C8.06944 2 8.26736 2.07986 8.42708 2.23958C8.58681 2.39931 8.66667 2.59722 8.66667 2.83333V7H12.8333C13.0694 7 13.2674 7.07986 13.4271 7.23958C13.5868 7.39931 13.6667 7.59722 13.6667 7.83333C13.6667 8.06944 13.5868 8.26736 13.4271 8.42708C13.2674 8.58681 13.0694 8.66667 12.8333 8.66667H8.66667V12.8333C8.66667 13.0694 8.58681 13.2674 8.42708 13.4271C8.26736 13.5868 8.06944 13.6667 7.83333 13.6667C7.59722 13.6667 7.39931 13.5868 7.23958 13.4271C7.07986 13.2674 7 13.0694 7 12.8333V8.66667Z"
        fill={fill}
      />
    </svg>
  );
};

const Button = ({
  type,
  text,
  size,
  plus,
  width,
  disabled,
  btnClass,
  clickHandler,
  icon,
  iconHeight,
  iconWidth,
}: ButtonProps) => {
  const baseStyles = `inline-flex  ${
    plus ? "gap-[4px]" : "gap-1"
  } flex justify-center items-center `;

  const colorStyle = {
    disabled:
      type === "primary"
        ? "!bg-blue-500 !bg-opacity-30 !text-white"
        : "!bg-gray20 !text-gray40",
    primary: "bg-blue40 !text-white ",
    secondary: "bg-blue5 !text-blue50",
    tertiary: "bg-gray5 !text-gray60",
    text_gray: "bg-neutral-white !text-gray50",
    text_blue: "bg-neutral-white !text-blue40",
    link_gray: "!text-gray50",
    link_blue: "!text-blue40",
  };
  const sizeStyles = {
    sm: ` h-8 px-2 py-1.5 rounded-lg ${TYPO.TYPO8_MD}`,
    md: ` h-10 px-[11px] py-2 rounded-[10px] ${TYPO.TYPO7_MD} `,
    lg: `h-12 px-4 py-[11px] rounded-xl ${TYPO.TYPO6_MD}`,
    xl: ` h-[54px] px-[18px] py-[13px] rounded-[14px] ${TYPO.TYPO5_MD}`,
  };

  const linkSizeStyles = {
    sm: `h-5 ${TYPO.TYPO8_MD}`,
    md: `h-6 ${TYPO.TYPO7_MD} `,
    lg: `h-[26px] ${TYPO.TYPO6_MD}`,
    xl: `h-7 ${TYPO.TYPO5_MD}`,
  };

  if (type === "link_gray" || type === "link_blue") {
    const buttonClass = `${baseStyles} ${
      type && disabled ? colorStyle["disabled"] : colorStyle[type]
    } ${size && linkSizeStyles[size]} ${width}  ${
      btnClass && btnClass
    } cursor-pointer whitespace-nowrap`;

    return (
      <button
        className={buttonClass}
        onClick={() => {
          if (clickHandler) {
            clickHandler();
          }
        }}
      >
        <p>{text}</p>
        {icon && (
          <Icon
            name={icon}
            width={iconWidth}
            height={iconHeight}
            fill={Fill({ type })}
          />
        )}
        {plus && <Plus type={type} />}
      </button>
    );
  } else {
    const buttonClass = `${btnClass && btnClass} ${baseStyles} ${
      type && disabled ? colorStyle["disabled"] : colorStyle[type]
    } ${size && sizeStyles[size]} ${width} ${
      disabled ? "" : "!cursor-pointer"
    } `;
    return (
      <button
        className={buttonClass}
        onClick={() => {
          if (clickHandler) {
            clickHandler();
          }
        }}
      >
        <p>{text}</p>
        {icon && (
          <Icon
            name={icon}
            width={iconWidth}
            height={iconHeight}
            fill={Fill({ type })}
          />
        )}

        {plus && <Plus type={type} />}
      </button>
    );
  }
};

export default Button;
