import { useState, MouseEvent } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import Head from "next/head";
import { useGlobalDispatch } from "contexts/SearchContext";
import { TYPO } from "styles/Typo";

const MenuBar = () => {
  const router = useRouter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const closeModal = () => {
    setIsOpenModal(false);
    setIsOpenSearchModal(false);
  };
  const dispatch = useGlobalDispatch();

  const handleMainPage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch({ type: "RESET_VALUE" });
    sessionStorage.removeItem(`main-scrollPosition`);
    sessionStorage.removeItem(`search-scrollPosition`);
    window.location.href = `/${router.locale}`;
  };

  if (
    // router.pathname === "/signup" ||
    // router.pathname === "/login" ||
    // router.pathname === "/profile/edit" ||
    // router.pathname === "/watchlist/search" ||
    // router.pathname === "/portfolio/detail" ||
    // router.pathname === "/portfolio/edit" ||
    // router.pathname === "/portfolio/add-assets"
    router.pathname !== "/" &&
    router.pathname !== "/portfolio" &&
    router.pathname !== "/profile" &&
    router.pathname !== "/search" &&
    router.pathname !== "/watchlist"
  ) {
    return <></>;
  }

  return (
    <header
      className="border  fixed  bottom-0 w-full z-30 bg-white pt-[4px] pb-[34px] px-[16px]  rounded-tl-[30px] rounded-tr-[30px] border-t border-zinc-200  "
      style={{ boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)" }}
    >
      <Head>
        <link rel="Icon" href="/images/favicon.png" type="image/x-icon" />
        <title>Riskweather</title>
        <meta
          property="og:image"
          content="https://riskweather.io/images/favicon.png"
        />
        <meta
          property="og:description"
          content={
            router.locale === "ko"
              ? "투자 리스크의 기준 - 리스크웨더"
              : "Invest with Confidence, We've Got the Risks Covered"
          }
        />
        <meta name="description" content={"투자 리스크의 기준 - 리스크웨더"} />
        <meta property="og:title" content="Riskweather" />
      </Head>
      <div className="h-full mx-auto flex items-center justify-between w-full">
        <div
          className={
            "w-[59px] h-11 text-gray-500 flex flex-col justify-center items-center text-xs"
          }
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: "RESET_VALUE" });
            sessionStorage.removeItem(`main-scrollPosition`);
            sessionStorage.removeItem(`search-scrollPosition`);
            window.location.href = `/${router.locale}`;
          }}
        >
          <div className={" h-[30px] flex justify-center items-center"}>
            <div className={""}>
              {router.asPath == "/" ? (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/homeBlack.svg`}
                  alt="logo"
                  className="w-full h-full cursor-pointer"
                />
              ) : (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/home.svg`}
                  alt="logo"
                  className="w-full h-full cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            className={`${
              router.asPath === "/"
                ? `${TYPO.MENU_ACTIVE}`
                : `${TYPO.MENU_INACTIVE}`
            }`}
          >
            {router.locale === "ko" ? "홈" : "HOME"}
          </p>
        </div>
        <div
          className={`${
            router.locale === "ko" ? "w-[59px]" : "w-[65px]"
          }  h-11 text-gray-500 flex flex-col justify-center items-center text-sm`}
          onClick={() => {
            dispatch({ type: "RESET_VALUE" });
            sessionStorage.removeItem(`search-scrollPosition`);
            sessionStorage.removeItem(`main-scrollPosition`);
            router.push("/watchlist");
          }}
        >
          <div className={" h-[30px] flex justify-center items-center"}>
            <div>
              {router.asPath == "/watchlist" ? (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/starBlack.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              ) : (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/star.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            className={`${
              router.asPath === "/watchlist"
                ? `${TYPO.MENU_ACTIVE}`
                : `${TYPO.MENU_INACTIVE}`
            }`}
          >
            {router.locale === "ko" ? "관심" : "My Watchlist"}
          </p>
        </div>
        <div
          className={
            "w-[59px] h-11 text-gray-500 flex flex-col justify-center items-center text-sm"
          }
          onClick={() => {
            dispatch({ type: "RESET_VALUE" });
            sessionStorage.removeItem(`search-scrollPosition`);
            sessionStorage.removeItem(`main-scrollPosition`);
            router.push("/portfolio");
          }}
        >
          <div className={" h-[30px] flex justify-center items-center"}>
            <div>
              {router.asPath == "/portfolio" ? (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/pieChartBlack.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              ) : (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/pieChart.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            className={`${
              router.asPath === "/portfolio"
                ? `${TYPO.MENU_ACTIVE}`
                : `${TYPO.MENU_INACTIVE}`
            }`}
          >
            {router.locale === "ko" ? "포트폴리오" : "Portfolio"}
          </p>
        </div>
        <div
          className={
            "w-[59px] h-11 text-gray-500 flex flex-col justify-center items-center text-sm"
          }
          onClick={() => {
            dispatch({ type: "RESET_VALUE" });
            sessionStorage.removeItem(`search-scrollPosition`);
            sessionStorage.removeItem(`main-scrollPosition`);
            router.push("/search");
          }}
        >
          <div className={" h-[30px] flex justify-center items-center"}>
            <div>
              {router.asPath == "/search" ? (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/searchBlack.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              ) : (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/search.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            className={`${
              router.asPath === "/search"
                ? `${TYPO.MENU_ACTIVE}`
                : `${TYPO.MENU_INACTIVE}`
            }`}
          >
            {router.locale === "ko" ? "탐색" : "Search"}
          </p>
        </div>
        <div
          className={
            "w-[59px] h-11 text-gray-500 flex flex-col justify-center items-center text-sm"
          }
          onClick={() => {
            dispatch({ type: "RESET_VALUE" });
            router.push("/profile");
          }}
        >
          <div className={" h-[30px] flex justify-center items-center"}>
            <div>
              {router.asPath == "/profile" ? (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/menuBlack.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              ) : (
                <Image
                  width={19}
                  height={20}
                  unoptimized
                  src={`/images/menuBar/menu.svg`}
                  alt="logo"
                  className="h-full w-full cursor-pointer"
                />
              )}
            </div>
          </div>
          <p
            className={`${
              router.asPath === "/profile"
                ? `${TYPO.MENU_ACTIVE}`
                : `${TYPO.MENU_INACTIVE}`
            }`}
          >
            MY
          </p>
        </div>
        {isOpenModal || isOpenSearchModal ? (
          <Image
            width={30}
            height={30}
            src={"/images/header/menu.svg"}
            alt=""
            className="cursor-pointer"
            onClick={closeModal}
          />
        ) : (
          ""
        )}
      </div>
      {/* {isOpenModal && (
        <Modal
          topRiskRow={504}
          onClose={() => setIsOpenModal(false)}
          setIsOpenContactModal={setIsOpenContactModal}
          setIsOpenAlarmModal={setIsOpenAlarmModal}
        />
      )}
      {isOpenContactModal && (
        <Contact onClose={() => setIsOpenContactModal(false)} />
      )}

      {isOpenSearchModal && (
        <SearchModal setIsOpenSearchModal={setIsOpenSearchModal} />
      )} */}
    </header>
  );
};

export default MenuBar;
