import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";

function B2bFooter({}: {}) {
  const TextLogo = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="37"
        viewBox="0 0 200 37"
        fill="none"
      >
        <path
          d="M30.915 21.0895C30.61 20.7645 30.18 20.5595 29.695 20.5595C28.775 20.5595 28.0301 21.3045 28.0301 22.2245C28.0301 22.7145 28.24 23.1545 28.58 23.4595C28.765 23.6445 28.865 23.8895 28.865 24.1495C28.865 24.6945 28.4251 25.1345 27.8801 25.1345C27.6651 25.1345 27.46 25.0645 27.295 24.9395L15.77 13.4145C18.815 11.1395 21.1501 11.2045 22.2751 11.4495C23.37 11.6895 24.24 12.2845 24.665 13.0895C25.565 14.8045 25.1501 16.1145 25.0351 16.4095C24.9251 16.6345 24.86 16.8845 24.86 17.1495C24.86 18.0695 25.605 18.8145 26.525 18.8145C27.15 18.8145 27.695 18.4695 27.98 17.9595C28.53 16.8895 29.075 14.3245 27.605 11.5345C26.725 9.86453 25.04 8.64453 22.98 8.19453C21.265 7.81953 17.7601 7.66953 13.5601 10.8995C13.5001 10.9445 13.4451 10.9895 13.3851 11.0395L10.9501 8.60454C10.6451 8.27454 10.2101 8.06953 9.72505 8.06953C8.80505 8.06953 8.06006 8.81453 8.06006 9.73452C8.06006 10.2195 8.26505 10.6545 8.59505 10.9595L11.03 13.3945C8.80005 16.1795 7.65005 19.6395 8.13005 22.6095C8.55005 25.2295 10.18 27.1645 12.72 28.0495C13.58 28.3495 14.445 28.4995 15.28 28.4995C16.975 28.4995 18.575 27.8945 19.875 26.7145C20.69 25.9795 21.32 25.0745 21.74 24.1045L24.82 27.1845L24.8101 27.1945C25.6251 28.0195 26.7151 28.4695 27.8751 28.4695C30.2551 28.4695 32.1901 26.5345 32.1901 24.1545C32.1901 23.0045 31.7251 21.8945 30.9101 21.0895H30.915ZM17.645 24.2495C16.92 24.9045 15.6651 25.5545 13.8251 24.9095C12.4551 24.4295 11.6501 23.4795 11.4251 22.0845C11.1201 20.1745 11.925 17.7845 13.42 15.7795L19 21.3595C19.015 22.3245 18.485 23.4895 17.645 24.2495Z"
          fill="#647A90"
        />
        <path
          d="M49.5353 28.5669L45.5503 21.3069H43.8403V28.5669H40.2153V10.2969H46.9953C48.3953 10.2969 49.5853 10.5419 50.5653 11.0319C51.5503 11.5219 52.2853 12.1869 52.7803 13.0219C53.2703 13.8569 53.5203 14.7919 53.5203 15.8269C53.5203 17.0169 53.1753 18.0869 52.4853 19.0419C51.7953 19.9969 50.7703 20.6569 49.4053 21.0169L53.7303 28.5619H49.5353V28.5669ZM43.8403 18.5919H46.8703C47.8553 18.5919 48.5853 18.3569 49.0703 17.8819C49.5553 17.4069 49.7953 16.7469 49.7953 15.9069C49.7953 15.0669 49.5553 14.4369 49.0703 13.9819C48.5853 13.5269 47.8553 13.2969 46.8703 13.2969H43.8403V18.5919Z"
          fill="#647A90"
        />
        <path
          d="M56.2803 11.6111C55.8553 11.2061 55.6453 10.7011 55.6453 10.1011C55.6453 9.50109 55.8553 8.99609 56.2803 8.59109C56.7053 8.18609 57.2353 7.98608 57.8703 7.98608C58.5053 7.98608 59.0403 8.19109 59.4603 8.59109C59.8853 8.99609 60.0953 9.50109 60.0953 10.1011C60.0953 10.7011 59.8853 11.2061 59.4603 11.6111C59.0353 12.0161 58.5053 12.2161 57.8703 12.2161C57.2353 12.2161 56.7003 12.0161 56.2803 11.6111ZM59.6603 13.9261V28.4761H56.0353V13.9261H59.6603Z"
          fill="#647A90"
        />
        <path
          d="M64.7804 27.8794C63.8504 27.4594 63.1104 26.8844 62.5654 26.1594C62.0204 25.4344 61.7254 24.6344 61.6704 23.7544H65.3204C65.3904 24.3044 65.6604 24.7644 66.1354 25.1244C66.6104 25.4844 67.2004 25.6694 67.9104 25.6694C68.6204 25.6694 69.1404 25.5294 69.5304 25.2544C69.9204 24.9794 70.1104 24.6244 70.1104 24.1944C70.1104 23.7294 69.8754 23.3794 69.4004 23.1494C68.9254 22.9144 68.1704 22.6644 67.1354 22.3894C66.0654 22.1294 65.1904 21.8644 64.5104 21.5894C63.8304 21.3144 63.2404 20.8894 62.7504 20.3244C62.2604 19.7594 62.0104 18.9894 62.0104 18.0244C62.0104 17.2344 62.2404 16.5094 62.6954 15.8544C63.1504 15.1994 63.8104 14.6844 64.6604 14.3044C65.5154 13.9244 66.5204 13.7344 67.6754 13.7344C69.3854 13.7344 70.7454 14.1594 71.7654 15.0144C72.7854 15.8694 73.3454 17.0194 73.4454 18.4644H69.9754C69.9254 17.8944 69.6854 17.4444 69.2654 17.1094C68.8404 16.7744 68.2754 16.6044 67.5704 16.6044C66.9154 16.6044 66.4104 16.7244 66.0554 16.9644C65.7004 17.2044 65.5254 17.5394 65.5254 17.9744C65.5254 18.4544 65.7654 18.8244 66.2504 19.0744C66.7354 19.3244 67.4854 19.5794 68.5004 19.8344C69.5354 20.0944 70.3904 20.3594 71.0654 20.6344C71.7404 20.9094 72.3204 21.3344 72.8104 21.9144C73.3004 22.4894 73.5554 23.2544 73.5754 24.1994C73.5754 25.0244 73.3454 25.7694 72.8904 26.4194C72.4354 27.0744 71.7754 27.5844 70.9254 27.9594C70.0704 28.3294 69.0754 28.5144 67.9354 28.5144C66.7954 28.5144 65.7104 28.3044 64.7754 27.8794H64.7804Z"
          fill="#647A90"
        />
        <path
          d="M84.2708 28.4678L79.4058 22.1378V28.4678H75.7808V9.11279H79.4058V19.9928L84.2208 13.9178H88.9308L82.6158 21.1028L88.9807 28.4678H84.2708Z"
          fill="#647A90"
        />
        <path
          d="M110 13.9236L105.805 28.4736H101.895L99.2803 18.2386L96.6653 28.4736H92.7303L88.5103 13.9236H92.1853L94.7203 24.8286L97.4653 13.9236H101.295L103.985 24.8036L106.52 13.9236H109.99H110Z"
          fill="#647A90"
        />
        <path
          d="M124.42 22.1994H113.935C114.02 23.2344 114.385 24.0444 115.02 24.6294C115.66 25.2144 116.445 25.5094 117.375 25.5094C118.72 25.5094 119.68 24.9344 120.25 23.7794H124.16C123.745 25.1594 122.95 26.2894 121.78 27.1794C120.605 28.0644 119.165 28.5094 117.455 28.5094C116.075 28.5094 114.835 28.2044 113.74 27.5944C112.645 26.9844 111.79 26.1194 111.175 24.9994C110.56 23.8794 110.255 22.5894 110.255 21.1244C110.255 19.6594 110.555 18.3444 111.16 17.2244C111.765 16.1044 112.61 15.2444 113.695 14.6394C114.78 14.0344 116.035 13.7344 117.45 13.7344C118.865 13.7344 120.035 14.0294 121.115 14.6144C122.195 15.1994 123.03 16.0294 123.625 17.1094C124.22 18.1844 124.52 19.4244 124.52 20.8194C124.52 21.3344 124.485 21.7994 124.415 22.2144L124.42 22.1994ZM120.77 19.7694C120.755 18.8394 120.415 18.0944 119.76 17.5344C119.105 16.9744 118.3 16.6944 117.355 16.6944C116.455 16.6944 115.7 16.9644 115.09 17.5094C114.475 18.0544 114.1 18.8044 113.965 19.7694H120.775H120.77Z"
          fill="#647A90"
        />
        <path
          d="M126.32 17.2244C126.9 16.1044 127.685 15.2444 128.675 14.6394C129.665 14.0344 130.775 13.7344 132 13.7344C133.07 13.7344 134.005 13.9494 134.81 14.3794C135.61 14.8094 136.255 15.3544 136.74 16.0094V13.9694H140.39V28.5194H136.74V26.1944C136.275 26.8644 135.63 27.4194 134.81 27.8594C133.99 28.2994 133.045 28.5194 131.975 28.5194C130.765 28.5194 129.665 28.2094 128.675 27.5894C127.68 26.9694 126.895 26.0944 126.32 24.9644C125.74 23.8344 125.455 22.5394 125.455 21.0744C125.455 19.6094 125.745 18.3444 126.32 17.2244ZM136.22 18.8644C135.875 18.2344 135.41 17.7544 134.82 17.4194C134.235 17.0844 133.605 16.9144 132.93 16.9144C132.255 16.9144 131.635 17.0794 131.065 17.4044C130.495 17.7294 130.035 18.2094 129.68 18.8394C129.325 19.4694 129.15 20.2144 129.15 21.0744C129.15 21.9344 129.325 22.6894 129.68 23.3344C130.035 23.9794 130.5 24.4744 131.08 24.8194C131.66 25.1644 132.275 25.3344 132.93 25.3344C133.585 25.3344 134.235 25.1644 134.82 24.8294C135.405 24.4944 135.87 24.0094 136.22 23.3844C136.565 22.7544 136.74 21.9994 136.74 21.1244C136.74 20.2494 136.565 19.4944 136.22 18.8644Z"
          fill="#647A90"
        />
        <path
          d="M147.373 17.1194V24.0444C147.373 24.5244 147.488 24.8744 147.723 25.0894C147.958 25.3044 148.348 25.4144 148.903 25.4144H150.583V28.4644H148.303C145.248 28.4644 143.723 26.9844 143.723 24.0194V17.1194H142.013V14.1494H143.723V10.6094H147.373V14.1494H150.583V17.1194H147.373Z"
          fill="#647A90"
        />
        <path
          d="M163.401 14.4228C164.246 14.8978 164.906 15.5928 165.381 16.5178C165.856 17.4378 166.091 18.5478 166.091 19.8378V28.4678H162.466V20.3278C162.466 19.1928 162.181 18.3178 161.611 17.7028C161.041 17.0928 160.266 16.7878 159.281 16.7878C158.296 16.7878 157.491 17.0928 156.911 17.7028C156.331 18.3128 156.046 19.1878 156.046 20.3278V28.4678H152.421V9.11279H156.046V15.7028C156.511 15.0828 157.131 14.5978 157.911 14.2428C158.686 13.8878 159.551 13.7128 160.501 13.7128C161.586 13.7128 162.556 13.9478 163.401 14.4228Z"
          fill="#647A90"
        />
        <path
          d="M181.987 22.1994H171.502C171.587 23.2344 171.952 24.0444 172.587 24.6294C173.227 25.2144 174.012 25.5094 174.942 25.5094C176.287 25.5094 177.247 24.9344 177.817 23.7794H181.727C181.312 25.1594 180.517 26.2894 179.347 27.1794C178.172 28.0644 176.732 28.5094 175.022 28.5094C173.642 28.5094 172.402 28.2044 171.307 27.5944C170.212 26.9844 169.357 26.1194 168.742 24.9994C168.127 23.8794 167.822 22.5894 167.822 21.1244C167.822 19.6594 168.122 18.3444 168.727 17.2244C169.332 16.1044 170.177 15.2444 171.262 14.6394C172.347 14.0344 173.602 13.7344 175.017 13.7344C176.432 13.7344 177.602 14.0294 178.682 14.6144C179.762 15.1994 180.597 16.0294 181.192 17.1094C181.787 18.1844 182.087 19.4244 182.087 20.8194C182.087 21.3344 182.052 21.7994 181.982 22.2144L181.987 22.1994ZM178.337 19.7694C178.322 18.8394 177.982 18.0944 177.327 17.5344C176.672 16.9744 175.867 16.6944 174.922 16.6944C174.022 16.6944 173.267 16.9644 172.657 17.5094C172.042 18.0544 171.667 18.8044 171.532 19.7694H178.342H178.337Z"
          fill="#647A90"
        />
        <path
          d="M189.4 14.3794C190.15 13.9494 191.01 13.7344 191.975 13.7344V17.5344H191.015C189.875 17.5344 189.02 17.7994 188.44 18.3344C187.86 18.8694 187.575 19.7994 187.575 21.1244V28.4894H183.95V13.9394H187.575V16.1594C188.04 15.3994 188.65 14.8094 189.4 14.3744V14.3794Z"
          fill="#647A90"
        />
      </svg>
    );
  };

  const BigLogo = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="230"
        height="230"
        viewBox="0 0 230 230"
        fill="none"
      >
        <g opacity="0.2">
          <path
            d="M201.453 137.015C199.033 134.431 195.588 132.809 191.757 132.809C184.425 132.809 178.487 138.747 178.487 146.06C178.487 149.965 180.173 153.474 182.858 155.894C184.315 157.369 185.122 159.303 185.122 161.383C185.122 165.7 181.603 169.21 177.286 169.21C175.572 169.21 173.96 168.66 172.612 167.652L80.8673 76.0259C105.116 57.9445 123.674 58.4486 132.665 60.4189C141.389 62.3251 148.318 67.0723 151.672 73.4416C158.847 87.0599 155.539 97.4799 154.623 99.826C153.743 101.604 153.239 103.602 153.239 105.719C153.239 113.041 159.177 118.97 166.509 118.97C171.485 118.97 175.82 116.23 178.093 112.189H178.111C182.501 103.666 186.826 83.2933 175.142 61.1063C168.14 47.8087 154.723 38.131 138.337 34.5478C124.682 31.5602 96.7767 30.3871 63.3541 56.0475C62.8867 56.4049 62.4285 56.7807 61.9611 57.1472L42.56 37.7736C40.1314 35.1526 36.6581 33.503 32.7999 33.503C25.4684 33.503 19.5299 39.4416 19.5299 46.7548C19.5299 50.6038 21.1703 54.068 23.7913 56.4874L43.1741 75.8518C25.4134 98.0114 16.2765 125.523 20.0797 149.121C23.4339 169.961 36.4107 185.321 56.6183 192.368C63.4824 194.76 70.3466 195.942 77.0183 195.942C90.5175 195.942 103.238 191.113 113.584 181.765C120.064 175.909 125.104 168.715 128.44 161.017L152.945 185.495L152.881 185.559C159.379 192.103 168.039 195.704 177.268 195.704C196.211 195.704 211.625 180.308 211.625 161.393C211.625 152.265 207.914 143.43 201.434 137.015H201.453ZM95.8053 162.126C90.0318 167.349 80.0608 172.491 65.3794 167.368C54.4921 163.565 48.0587 156.013 46.2716 144.915C43.8247 129.739 50.2582 110.722 62.1444 94.7947L106.583 139.178C106.684 146.858 102.486 156.105 95.8053 162.135V162.126Z"
            fill="#BDC7D1"
          />
        </g>
      </svg>
    );
  };
  return (
    <div className="mt-[72px] px-[24px] gap-[40px] flex flex-col relative h-[220px] max-w-[1000px] w-full">
      <div className="absolute bottom-[-10px] right-0">
        <BigLogo />
      </div>
      <div className="flex justify-between">
        <TextLogo />
        <div className="flex gap-[90px] text-[16px] text-gray40 font-normal leading-[25.6px]">
          <div className="w-[142px]">GNE Tech Holdings Riskweather</div>
          <div className="w-[111px]">지엔이테크홀딩스 리스크웨더</div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <span className={`${TYPO.TYPO7_1_MD} text-gray30`}>제휴문의</span>
          <span className={`${TYPO.TYPO7_1_RG} text-gray60`}>
            010-5446-9191
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <span className={`${TYPO.TYPO7_1_MD} text-gray30`}>이메일</span>
          <span className={`${TYPO.TYPO7_1_RG} text-gray60`}>jay@gne.ai</span>
        </div>
        <div className="flex gap-2 items-center">
          <span className={`${TYPO.TYPO7_1_MD} text-gray30`}>CPO</span>
          <span className={`${TYPO.TYPO7_1_RG} text-gray60`}>이재웅</span>
        </div>
      </div>
    </div>
  );
}

export default B2bFooter;
